import PrivacyCookieNorma from '../views/news/PrivacyCookieNorma';
import PercheAvereUnSito from '../views/news/PercheAvereUnSito';
import MotiviFondamentoSuccesso from '../views/news/MotiviFondamentoSuccesso';
import WordpressSuccesso from '../views/news/WordpressSuccesso';
import GuidaHosting from '../views/news/GuidaHosting';
import SitoGratisSoccorritori from '../views/news/SitoGratisSoccorritori';
import GuidaPiattaforme from '../views/news/GuidaPiattaforme';

export default [
	{
		imgs: {
			v: require('../imgs/news/guida-piattaforme.webp'),
			h: require('../imgs/news/guida-piattaforme-h.webp')
		},
		title: 'Guida alla scelta della piattaforma perfetta per il tuo sito web: trucchi e strategie',
		slug: 'guida-alla-scelta-della-piattaforma-perfetta-per-il-tuo-sito-web-trucchi-e-strategie',
		date:'21 Ottobre 2024',
		category: 'guide',
		content: <GuidaPiattaforme />
	},
	{
		imgs: {
			v: require('../imgs/news/sito-gratis-soccorritori.webp'),
			h: require('../imgs/news/sito-gratis-soccorritori-h.webp')
		},
		title: 'Un Impegno per la comunità: il nostro lavoro Gratuito per l\'Associazione Italiana Soccorritori',
		slug: 'un-impegno-per-la-comunita-il-nostro-lavoro-gratuito-per-lassociazione-italiana-soccorritori',
		date:'13 Settembre 2023',
		category: 'progetti',
		content: <SitoGratisSoccorritori />
	},
	{
		imgs: {
			v: require('../imgs/news/guida-definitiva-hosting.webp'),
			h: require('../imgs/news/guida-definitiva-hosting-h.webp')
		},
		title: 'La guida definitiva all\'Hosting: scegli il migliore Hosting per il tuo sito web e sorprendi i tuoi visitatori!',
		slug: 'la-guida-definitiva-all-hosting-scegli-il-migliore-hosting-per-il-tuo-sito-web-e-sorprendi-i-tuoi-visitatori',
		date:'7 Giugno 2023',
		category: 'guide',
		content: <GuidaHosting />
	},
	{
		imgs: {
			v: require('../imgs/news/wordpress-segreto-successo.webp'),
			h: require('../imgs/news/wordpress-segreto-successo-h.webp')
		},
		title: '20 anni di WordPress: il segreto del successo delle piccole imprese online con siti web che vendono',
		slug: '20-anni-di-wordpress-il-segreto-del-successo-delle-piccole-imprese-online-con-siti-web-che-vendono',
		date:'12 Maggio 2023',
		category: 'wordpress',
		content: <WordpressSuccesso />
	},
	{
		imgs: {
			v: require('../imgs/news/motivi-sitoweb-fondamento-successo.webp'),
			h: require('../imgs/news/motivi-sitoweb-fondamento-successo-h.webp')
		},
		title: '5 motivi per cui il sito web è il fondamento per il successo del business online',
		slug: '5-motivi-per-cui-il-sito-web-è-il-fondamento-per-il-successo-del-business-online',
		date:'7 Febbraio 2023',
		category: 'perplessità',
		content: <MotiviFondamentoSuccesso />
	},
	{
		imgs: {
			v: require('../imgs/news/privacy-e-cookie.webp'),
			h: require('../imgs/news/privacy-e-cookie-h.webp')
		},
		title: 'Privacy policy e cookie banner: sicuro di essere a norma?',
		slug: 'privacy-policy-cookie-banner-essere-norma',
		date:'3 Ottobre 2022',
		category: 'conformità',
		content: <PrivacyCookieNorma />
	},
   {
		imgs: {
			v: require('../imgs/news/perche-avere-un-sito.webp'),
			h: require('../imgs/news/perche-avere-un-sito-h.webp')
		},
		title: 'Perchè dovresti avere un sito web',
		slug: 'perche-dovresti-avere-un-sito-web',
		date:'27 Settembre 2022',
		category: 'perplessità',
		content: <PercheAvereUnSito />
	}
]